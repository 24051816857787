export default {
  props: {
    isCheck: {
      type: Boolean
    }
  },
  methods: {
    click: function click() {
      this.$emit('click');
    }
  }
};