import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "span-checkbox",
    on: {
      "click": _vm.click
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isCheck,
      expression: "isCheck"
    }],
    staticClass: "Checkbox",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.isCheck) ? _vm._i(_vm.isCheck, null) > -1 : _vm.isCheck
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.isCheck = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isCheck = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isCheck = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "check1"
  }), _c('label', {
    staticClass: "c"
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };