var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "similar-list"
  }, [_c('div', {
    staticClass: "bar v2-g-flex-row"
  }, [_c('div', {
    staticClass: "v2-g-flex-row v2-items-center"
  }, [_c('span', {
    staticClass: "switch",
    class: !_vm.isColList ? 'sel' : '',
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.swich(false);
      }
    }
  }, [_vm._v("大图模式")]), _c('span', {
    staticClass: "switch",
    class: _vm.isColList ? 'sel' : '',
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.swich(true);
      }
    }
  }, [_vm._v("列表模式")])]), _c('div', [_c('div', {
    staticClass: "page"
  }, [_c('span', [_vm._v("共"), _c('i', {
    staticClass: "blue"
  }, [_vm._v(_vm._s(_vm.list.length))]), _vm._v("个商品")])])])]), _c('div', {
    staticClass: "list v2-g-flex-row"
  }, [_vm.list.length == 0 ? [_vm._m(0)] : !_vm.isColList ? _vm._l(_vm.list, function (item) {
    return _c('div', {
      staticClass: "product-wrapper"
    }, [_c('ProductItem', {
      attrs: {
        "product": item,
        "isShowSimilar": true,
        "pageCode": "13"
      }
    })], 1);
  }) : [_vm._m(1), _vm._l(_vm.list, function (item) {
    return _c('div', {
      staticClass: "product-wrapper-col"
    }, [_c('ProductItemCol', {
      attrs: {
        "product": item,
        "isShowSimilar": true,
        "pageCode": "13"
      }
    })], 1);
  })]], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nodata"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/nodata.png")
    }
  }), _c('span', [_vm._v("暂无相关商品")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "t v2-g-flex-row v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "product"
  }, [_vm._v("商品")]), _c('div', {
    staticClass: "price"
  }, [_vm._v("单价")]), _c('div', {
    staticClass: "num"
  }, [_vm._v("数量")]), _c('div', {
    staticClass: "op"
  }, [_vm._v("操作")])]);
}];
export { render, staticRenderFns };