import { purchaseQuantityProcessing, urlDownloadFile } from '@/utils/index'
import { AccountStorage } from "@services/account";

const accountStorage = new AccountStorage();

export default {
  data() {
    return {
      lstMyPlan: [],
      lstRebate: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 20
      },
      list: [],
      accData: {},
      maxNumber: '',
      userInfo: accountStorage.getCacheUserInfo(),
      productInformationList: [],  // 产品资料列表
    }
  },
  filters: {
    setInvoiceType(type) {
      if (type == 1) return '纸质专票';
      if (type == 2) return '纸质普票';
      if (type == 51) return '电子增值税普通发票';
      if (type == 52) return '全面数字化电子普通发票';
      if (type == 53) return '全面数字化电子专用发票';
    },
    setTicketType(type) {
      if (!type) return '';
      if (type == 1) return '纸质专票';
      if (type == 2) return '纸质普票';
      if (type == 51) return '电子普票';
      if (type == 52) return '数电普票';
      if (type == 53) return '数电专票';
    },
    setTypeOfUse(type) {
      // 1.商城  2.线下  3.转移余额  4.线下扣除  5.现款客户  6.退货扣除  7.退货退返利  8.退货退红包  9.拼单拼团退货扣减
      if (!type) return '';
      if (type == 1) return '商城抵扣';
      if (type == 2 || type == 4) return '线下抵扣';
      if (type == 3) return '转移余额';
      if (type == 5) return '现款客户';
      if (type == 6) return '退货扣除';
      if (type == 7) return '退货退返利';
      if (type == 8) return '退货退红包';
      if (type == 9) return '拼单拼团退货扣减';
    }
  },
  methods: {
    async getCommonProductList(params, type, isList = false) {
      let url;
      if (type === '我的计划') {  // 我的计划
        url = '/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/myPlan';
      } else if (type === '返利专区') {  // 返利专区
        url = '/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/rebates';
      } else if (type === '新品红包') {  // 新品红包
        url = '/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/newProduct';
      }
      const result = await this.$getHttpClient().post(url, params);
      let { code, data } = result.data;
      if (code == 200) {
        let resultData = purchaseQuantityProcessing(data.list)
        this.lstMyPlan = resultData;
        if (isList) {
          this.list = resultData;
          if (data) {
            this.pageInfo.total = data.total;
            this.pageInfo.pageNum = data.pageNum;
            this.pageInfo.pageSize = data.pageSize;
          }
          if (this.isScroll) {
            this.toTarget()
          }
        }
      }
    },

    getBalancePageList() {
      if (this.isExternal) return;
      const data = {
        page: this.page,
        pageSize: this.pageSize
      };
      this.$getHttpClient().post("/pjyy-deepexi-user-center/api/v1/balance/pageList", data)
        .then(res => {
          if (res.data.data && res.data.data.balanceStatisticsList) {
            if (this.page < Math.ceil(res.data.data.balanceStatisticsList.total / this.pageSize)) {
              this.page++;
            }
          }
          this.accData = res.data.data || {};
          this.totalBalanceAmount = this.accData.totalBalanceAmount;
          this.pointsMoney = this.accData.pointsMoney;
          this.points = this.accData.points;
          this.maxNumber = this.formatNum(this.totalBalanceAmount);
          if (res.data.data && res.data.data.balanceStatisticsList) {
            this.dataList = this.dataList.concat(res.data.data.balanceStatisticsList.list || []);
            this.totalPageNumber = Math.ceil(res.data.data.balanceStatisticsList.total / this.pageSize);
          }
          setTimeout(() => {
            this.busy = false;
          }, 1000);
          if (this.page >= this.totalPageNumber) {
            this.bottomTitle = "数据已加载完";
            this.busy = true;
          }
        });
    },

    async findAFriendToPay (orderNumber, amount, flag = false) {
      let params = {
        channelType: 6,  // 1 app，2 H5，3 pc，4 小程序，5 账单PC，6 账单H5
        orderNumber: orderNumber,  // 预订单号
        orderType: 'order',  // 订单类型  bill 还是 order
        code: this.userInfo.account,  // 客户编码
        staffCode: this.userInfo.staffCode  // 业务员编码
      }
      let api = '/pjyy-deepexi-pay-center/pay/v2/PublicPay/friendToPay';
      let res = await this.$getHttpClient().get(api, { params })
      let { code, data } = res.data;
      if (code == 200) {
        // 创建输入框元素
        let oInput = document.createElement("input");
        // 将想要复制的值
        oInput.value = `【普健医药】代付一笔订单：￥${amount}，请在三小时内完成支付，超时后订单自动取消\r\n ${data}`;
        // 页面底部追加输入框
        document.body.appendChild(oInput);
        // 选中输入框
        oInput.select();
        // 执行浏览器复制命令
        document.execCommand("Copy");
        // 弹出复制成功信息
        this.$buefy.toast.open("已复制链接，去微信粘贴找朋友付吧~");
        // 复制后移除输入框
        oInput.remove();

        if (flag) {
          this.payment.list.offlinePayment.show = false;
        }
      }
    },

    /**
     * 取消订单逻辑
     * @param obj
     * @param type  list.列表  detail.详情
     */
    async confirmCloseOrder(obj, type) {
      if (obj.orderStatus == "01") {
        const url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/cancelOrder?orderNumber=${obj.orderNumber}`;
        this.$getHttpClient().get(url).then(res => {
          if (res.data.code === "200") {
            this.$messageTip('取消成功！', 'success');
            this.closeOrderCallback(type)
          } else {
            this.$messageTip('取消失败！', 'error');
          }
        });
      } else {
        const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/order/orderCancelCommit`;
        let params = {
          erpOrderId: obj.erpOrderId,
          customerCode: obj.companyId,
          cancelManName: accountStorage.getCacheUserInfo().loginCompanyAccount
        }
        if (obj.isAuditOrder && obj.isAuditOrder == "1") {
          params.rejectReason = "客户自主取消"
        }
        this.$getHttpClient().post(URL, params).then(res => {
          let { code, data } = res.data;
          if (code == 200) {
            if (data.result) {
              this.$messageTip('订单已取消，退款预计3个工作日内到账', 'success');
              this.closeOrderCallback(type)
            } else {
              this.$messageTip(data.reason, 'error');
            }
          }
        });
      }
    },

    // 取消订单后逻辑
    closeOrderCallback(type) {
      if (type === 'detail') {
        this.showMsg = ""
        this.showCancel = false
        setTimeout(async () => {
          await this.getOrderDetail();
        }, 1000)
      } else {
        setTimeout(async () => {
          await this.getOrderList();
        }, 1000)
      }
    },

    // 下载产品资料信息
    async downloadProductInformation(productCodes, erpOrderId = null) {
      let params = {
        productCodes: productCodes
      }
      if (erpOrderId) params.erpOrderId = erpOrderId;
      let url = '/pjyy-deepexi-product-center/api/v1/product/infoClass/queryProductInfoDownUrlByCode';
      const result = await this.$getHttpClient().post(url, params);
      let { code, data } = result.data;
      if (code == 200) {
        if (data && data.length) {
          for (let i in this.productInformationList) {
            for (let j = 0; j < data.length; j++) {
              if (this.productInformationList[i].productCode == data[j].productCode) {
                this.productInformationList[i].speed = j + 1 == data.length ? 100 : j + 10;
                this.productInformationList[i].downType = j + 1 == data.length ? 2 : 1;
                this.productInformationList[i].isShowDown = false;
                this.$forceUpdate()
                urlDownloadFile(data[j].pictureUrl, `${data[j].pictureName}`)
              }
            }
          }
        } else {
          this.$buefy.toast.open({
            message: `暂无商品资料`,
            type: "is-danger"
          });
        }
      }
    },
      toTop() {
          let timer = setInterval(function () {
              let osTop = document.documentElement.scrollTop || document.body.scrollTop;
              let ispeed = Math.floor(-osTop / 5);
              document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;

              if (osTop === 0) {
                  clearInterval(timer);
              }
          }, 30);
      },
      toTarget() {
          // 获取目标元素
          const targetElement = this.$refs.myplan;
          const targetPosition = targetElement.offsetTop; // 获取目标元素的顶部距离

          // 获取当前滚动位置
          const startPosition = document.documentElement.scrollTop || document.body.scrollTop;

          // 计算总的滚动距离
          const distance = targetPosition - startPosition;

          // 动画持续时间
          const duration = 500; // 例如 500 毫秒

          // 每次滚动的时间间隔
          const interval = 30;

          // 每次滚动的步长
          const step = distance / (duration / interval);

          let startTime = null;

          // 动画函数
          const animate = (currentTime) => {
              if (!startTime) startTime = currentTime;
              const timeElapsed = currentTime - startTime;
              const progress = Math.min(timeElapsed / duration, 1); // 计算动画进度

              // 使用缓动公式来平滑滚动
              const easeInOut = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
              const scrollPosition = startPosition + (distance * easeInOut(progress));

              document.documentElement.scrollTop = document.body.scrollTop = scrollPosition;

              if (progress < 1) {
                  requestAnimationFrame(animate);
              }
          };

          // 启动动画
          requestAnimationFrame(animate);
      }

  }
}
