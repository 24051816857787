<!-- 我的计划 -->
<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>

        <div class="v2-g-w1200">
            <div class=" title v2-g-flex-row  v2-g-flex-space-between">
                <div><img src="@/assets-v2/images/zuoxian.png" /></div>
                <div><img src="@/assets-v2/images/dh_lingxing.png" /></div>
                <div>
                    <span>我 / 的 / 计 / 划</span>
                </div>
                <div><img src="@/assets-v2/images/dh_lingxing.png" /></div>
                <div><img src="@/assets-v2/images/zuoxian.png" /></div>
            </div>
            <ProductList
              :list="list"
              :pageInfo="pageInfo"
              v-on:getdata="getDataByPage"
              :hideSearch="true"
              @getGoodsList="getCommonProductList({ page: 1, pageSize: 20 }, '我的计划', true)"
            />
        </div>
        <PageBottom></PageBottom>
    </div>
</template>
<style scoped lang="less">
.title {
    margin: 30px auto;
    height: 40px;

    line-height: 40px;
    font-size: 16px;
    font-weight: 400;


    span {
        color: #666;
        font-size: 1.75rem;
    }

}
</style>
<script>
import ProductList from "@/components-v2/product/product-list";
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import publicMethods from '@mixin/publicMethods'

export default {
    data() {
        return {
            list: [],
            pageInfo: {
                total: 0,
                pageNum: 1,
                pageSize: 10
            },
            queryParam: {
                productName: null,
                code: '1',
                activity: null,
                page: 1,//请求页
            },
        };
    },
    components: {
        ProductList,
        PageTop,
        PageTop1,
        PageBottom
    },
    mixins: [publicMethods],
    created() {
        this.getCommonProductList({ page: 1, pageSize: 20 }, '我的计划', true);
    },
    methods: {
        getDataByPage(data) {
          let params = {
            page: data.pageNum,
            pageSize: 20
          }
          this.getCommonProductList(params, '我的计划', true);
        }
    }
}
</script>
